import Vue from "vue";
import VueRouter from "vue-router";
import { Message } from "element-ui";
Vue.use(VueRouter);

const originalPush = VueRouter.prototype.push;
//修改原型对象中的push方法
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};
//解决vue路由重复导航错误
const routes = [
  {
    path: "/",
    name: "Home",
    component: () => import("@/views/OutIndex/index.vue"),
    redirect: "/Home",
    children: [
      {
        path: "/Home",
        name: "Home",
        component: () => import("@/views/Home/index.vue"),
      },
      {
        path: "/fusion",
        name: "fusion",
        component: () => import("@/views/Fusion/index.vue"),
      },
      {
        path: "/Roll",
        name: "Roll",
        component: () => import("@/views/Roll/index.vue"),
      },
      {
        path: "/room",
        name: "room",
        component: () => import("@/views/Roll/roomDetail.vue"),
      },
      {
        path: "/OpeningBox",
        name: "OpeningBox",
        component: () => import("@/views/OpeningBox/index.vue"),
      },
      {
        path: "/newuser",
        name: "newuser",
        component: () => import("@/views/NewUser/index.vue"),
      },
      {
        path: "/kaijian",
        name: "kaijian",
        component: () => import("@/views/kaijian/index.vue"),
      },
      // {
      //   path: "/Ranking",
      //   name: "Ranking",
      //   component: () => import("@/views/Ranking/index.vue"),
      // },
      {
        path: "/Mail",
        name: "Mail",
        component: () => import("@/views/Mail/index.vue"),
      },
      {
        path: "/Datapage",
        name: "Datapage",
        component: () =>
          import("@/components/common/PublicFooter/Datapage.vue"),
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
});

// 导航守卫
router.beforeEach((to, from, next) => {
  if (
    to.path === "/Warehouse" ||
    to.path === "/about" ||
    to.path === "/Extension" ||
    to.path === "/Recharge" ||
    to.path === "/CreatingRooms" ||
    to.path === "/WelfareInfo"
  ) {
    let token = localStorage.getItem("token");
    if (token === null || token === "") {
      next("/Home");
      Message({
        message: "您还未登陆，请先登陆!",
        type: "error",
      });
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;
